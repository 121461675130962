<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card v-if="searchPanel">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('farm_report.farm_report_title') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('org_pro_division.division')"
                  label-for="division_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.division_id"
                      :options="divisionList"
                      id="division_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="3"
                  :label="$t('org_pro_district.district')"
                  label-for="district_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.district_id"
                      :options="districtList"
                      id="district_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('org_pro_upazilla.upazilla')"
                  label-for="upazilla_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.upazilla_id"
                      :options="upazilaList"
                      id="upazilla_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Fiscal Year" vid="fiscal_year" rules="">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="fiscal_year"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('dae_subsidy.fiscal_year')}}
                    </template>
                    <b-form-select
                        plain
                        v-model="search.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Season" vid="season_id">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="season_id"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{$t('farm_config.season')}}
                    </template>
                    <b-form-select
                        plain
                        v-model="search.season_id"
                        id="season_id"
                        :options="seasonList"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Circular" vid="circular_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="circular_id"
                        slot-scope="{ valid, errors }"
                    >
                        <template v-slot:label>
                            {{$t('farm_config.circular')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.circular_id"
                            id="circular_id"
                            :options="circularList"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('farm_config.machine_type')"
                    label-for="name"
                    >
                    <b-form-select
                        plain
                        v-model="search.machine_type_id"
                        :options="machineTypeList"
                        id="machine_type_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      :label="$t('farm_config.instrument_name')"
                      label-for="name"
                      >
                      <b-form-select
                          plain
                          v-model="search.instrument_name_id"
                          :options="instrumentList"
                          id="instrument_name_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('farm_report.engine_no')"
                  label-for="engine_no"
                  >
                  <b-form-input
                    plain
                    v-model="search.engine_no"
                    id="engine_no"
                    >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('farm_report.chassis_no')"
                  label-for="chassis_no"
                  >
                  <b-form-input
                    plain
                    v-model="search.chasis_no"
                    id="chassis_no"
                    >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('common_config.farmer_mobile_name')"
                  label-for="mobile_no"
                  >
                  <b-form-input
                    plain
                    v-model="search.mobile_no"
                    id="mobile_no"
                    >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('farm_report.farm_report_title') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                        <b-button v-if="!searchPanel" class="btn_add_new m-2" @click="sidebarMini">
                          <i class="fas fa-unlock"></i>{{ $i18n.locale === 'en' ? 'Show/Hide Menu' : 'মেনু লক/আনলক করুন' }}
                        </b-button>
                        <b-button v-if="!searchPanel" class="btn_add_new m-2" @click="searchPanelShow">
                          <i class="fas fa-unlock"></i>{{ $i18n.locale === 'en' ? 'Unlock Search Panel' : 'অনুসন্ধান প্যানেল আনলক করুন' }}
                        </b-button>
                        <b-button class="btn_add_new" @click="pdfExport">
                          <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                        </b-button>
                        <export-excel
                            class="btn_add_new ml-2 p-1"
                            :data="excelDatas"
                            :title="headerValue"
                            worksheet="Report Sheet"
                            :before-generate = "headerDataExcel"
                            name="farm_machinery_report.xls">
                            <i class="fas fa-download"></i> {{ $t('globalTrans.export_excel') }}
                        </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="2">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('farm_report.farm_report_title') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                <div class="text-black">
                                <b-row>
                                  <b-col md="3"><strong>{{ $t('org_pro_division.division') }}: </strong>{{ search.division_id ? ($i18n.locale === 'en' ? searchResult.division_name : searchResult.division_name_bn ) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('org_pro_district.district') }}: </strong>{{ search.district_id  ?  ($i18n.locale === 'en' ? searchResult.district_name : searchResult.district_name_bn) : $t('globalTrans.all') }}</b-col>
                                  <b-col md="3"><strong>{{ $t('org_pro_upazilla.upazilla') }}: </strong>{{ search.upazilla_id ? ($i18n.locale === 'en' ? searchResult.upazilla_name : searchResult.upazilla_name_bn) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('dae_subsidy.fiscal_year') }}: </strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchResult.fiscal_year : searchResult.fiscal_year_bn) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('farm_config.season') }}: </strong>{{ search.season_id ? ($i18n.locale === 'en' ? searchResult.season : searchResult.season_bn) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('farm_config.circular') }}: </strong>{{ search.circular_id ? ($i18n.locale === 'en' ? searchResult.circular_name : searchResult.circular_name_bn) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('farm_config.machine_type') }}: </strong>{{ search.machine_type_id ? ($i18n.locale === 'en' ? searchResult.machine_type : searchResult.machine_type_bn) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('farm_config.instrument_name') }}: </strong>{{ search.instrument_name_id ? ($i18n.locale === 'en' ? searchResult.instrument_name : searchResult.instrument_name_bn) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('farm_report.engine_no') }}: </strong>{{ search.engine_no ? search.engine_no : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('farm_report.chassis_no') }}: </strong>{{ search.chasis_no ? search.chasis_no : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('common_config.farmer_mobile_name') }}: </strong>{{ search.mobile_no ? search.mobile_no : $t('globalTrans.all')  }}</b-col>
                                </b-row>
                              </div>
                                <template>
                                    <div class="tableFixHead col-12">
                                        <b-table-simple v-if='datas.length > 0' class="tg mt-3" :sticky-header="stickyHeader"  bordered hover row-hovered small caption-top responsive show-empty>
                                            <b-thead>
                                            <b-tr>
                                                <!-- <b-th class="text-center" style="background-color: #609513">{{ $t('globalTrans.sl_no') }}</b-th> -->
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center sticky-col first-col topzindex">{{ $t('globalTrans.district') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center sticky-col second-col topzindex">{{ $t('globalTrans.upazila') }}</b-th>
                                                <!-- <b-th style="background-color: #609513" class="text-center sticky-col third-col topzindex">{{ $t('globalTrans.union') }}</b-th> -->
                                                <b-th style="background-color: #609513" class="text-center sticky-col fourth-col topzindex">{{ $t('dae_grant_allocation_distribution.farmer_name') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_mobile_number') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('elearning_tim.name_of_father_husband') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('globalTrans.address') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('dae_grant_allocation_distribution.farmer_nid') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('farm_report.bill_no') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('farm_report.supplier_company_name') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('dae_subsidy.model') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('dae_subsidy.brand_name') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('farm_config.instrument_name') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('farm_report.engine_no') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('farm_report.chassis_no') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $n(50, { useGrouping: false }) }}%</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $n(70, { useGrouping: false }) }}%</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('globalTrans.total') }}</b-th>
                                                <b-th style="background-color: #609513" class="text-center">{{ $t('farm_report.date_of_supply_of_equipment_to_the_farmer') }}</b-th>
                                            </b-tr>
                                            </b-thead>
                                            <b-tbody>
                                            <template v-for="district in datas">
                                                <template v-for="(upazilla, upazilaIndex) in district.upazilla">
                                                <b-tr v-for="(info, infoIndex) in upazilla.list" :key="infoIndex">
                                                    <b-td class="text-center sticky-col">{{ $n(info.farmer_serial, { useGrouping: false }) }}</b-td>
                                                    <slot v-if="upazilaIndex == 0 && infoIndex == 0">
                                                    <b-td :rowspan="district.rowSpan + district.upazilla.length" class="text-center sticky-col second-col firstColumn">
                                                    {{ getColumnName($store.state.commonObj.districtList, district.district_id)}}
                                                    </b-td>
                                                    </slot>
                                                    <slot v-if="infoIndex == 0">
                                                    <b-td :rowspan="upazilla.rowSpan" class="text-center sticky-col secondColumn">
                                                        {{ getColumnName($store.state.commonObj.upazilaList, info.upazilla_id)}}
                                                    </b-td>
                                                    </slot>
                                                    <!-- <b-td class="text-center thirdColumn">
                                                    {{ getColumnName($store.state.commonObj.unionList, info.union_id)}}
                                                    </b-td> -->
                                                    <b-td class="text-center fourthColumn">{{ ($i18n.locale === 'bn') ? info.name_bn : info.name }}</b-td>

                                                    <b-td class="text-center">
                                                        {{ checkLanguage(info.mobile_no) === 'english' ?
                                                        ($i18n.locale == 'bn' ? '০': '0') + $n(info.mobile_no, { useGrouping: false }) : info.mobile_no }}
                                                    </b-td>
                                                    <b-td class="text-center">{{ ($i18n.locale === 'bn') ? info.father_name_bn : info.father_name }}</b-td>
                                                    <b-td class="text-center">{{ ($i18n.locale === 'bn') ? info.address_bn : info.address }}</b-td>
                                                    <b-td class="text-center">{{ $n(info.nid_no, { useGrouping: false }) }}</b-td>
                                                    <b-td class="text-center"> {{ info.bill_no }} </b-td>
                                                    <b-td class="text-center">
                                                    {{ ($i18n.locale === 'bn') ? info.supplier_name_bn : info.supplier_name }}
                                                    </b-td>
                                                    <!-- <b-td class="text-center">{{ $n(1) }}</b-td> -->
                                                    <b-td class="text-center">{{ info.spp_model }}</b-td>
                                                    <b-td class="text-center">
                                                    {{ getColumnName($store.state.incentiveGrant.commonObj.macBrandList, info.spp_brand_id)}}
                                                    </b-td>
                                                    <b-td class="text-center">{{ getInstrumentName(info.instrument_id) }}</b-td>
                                                    <b-td class="text-center">{{ info.engine_no ? info.engine_no : '' }}</b-td>
                                                    <b-td class="text-center">{{ info.chasis_no ? info.chasis_no : '' }}</b-td>
                                                    <b-td class="text-center">
                                                    <slot v-if="!info.haor_costal_area">
                                                        {{ $n(info.farmer_value) }}
                                                    </slot>
                                                    </b-td>
                                                    <b-td class="text-center">
                                                    <slot v-if="info.haor_costal_area">
                                                        {{ $n(info.farmer_value) }}
                                                    </slot>
                                                    </b-td>
                                                    <b-td class="text-center">
                                                    {{ $n(info.farmer_value) }}
                                                    </b-td>
                                                    <b-td class="text-center">
                                                    <slot v-if="info.distributed_date">{{ info.distributed_date | dateFormat }}</slot>
                                                    <slot v-if="info.excel_date">{{ info.excel_date }}</slot>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr :key="upazilla.upazilla_id">
                                                    <b-th colspan="3" class="thirdColumn" style="text-align: right"><b>{{ $t('globalTrans.total') }}</b></b-th>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-th>{{ $n(upazilla.total_shomotol) }}</b-th>
                                                    <b-th>{{ $n(upazilla.total_hawor) }}</b-th>
                                                    <b-th>{{ $n(upazilla.total) }}</b-th>
                                                    <b-th></b-th>
                                                </b-tr>
                                                </template>
                                                <b-tr :key="district.district_id">
                                                <b-th class="fourthColumn" style="text-align: right"><b>{{ $t('globalTrans.total') }}</b></b-th>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-td></b-td>
                                                <b-th>{{ $n(district.total_shomotol) }}</b-th>
                                                <b-th>{{ $n(district.total_hawor) }}</b-th>
                                                <b-th>{{ $n(district.total) }}</b-th>
                                                <b-th></b-th>
                                                </b-tr>
                                            </template>
                                            </b-tbody>
                                        </b-table-simple>
                                        <div class="panel-body text-center mt-3" v-else>
                                            <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                                        </div>
                                    </div>
                                </template>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<style >
.tableFixHead th div {
  color: white;
}
/* .tableFixHead  th {
  background: #609513;
  /* color: var(--iq-white); */
 */
.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.first-col {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  left: 0px;
  background-color: white;
}

.second-col {
  width: 40px;
  min-width: 90px;
  max-width: 90px;
  left: 40px;
  background-color: white;
}
.third-col {
  width: 40px;
  min-width: 90px;
  max-width: 90px;
  left: 127px;
  background-color: rgb(39, 124, 88);
}
.fourth-col {
  width: 110px;
  min-width: 110px;
  max-width: 110px;
  left: 127px;
  background-color: rgb(39, 124, 88);
}
.underzindex {
  z-index: 3 !important;
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
  top: 0;
}
.topzindex {
  z-index: 5 !important;
}
.rightzindex {
  z-index: 4 !important;
}
.firstColumn {
    position:sticky;
    left:0px !important;
    background-color: white;
}
.secondColumn {
    position:sticky;
    left:40px !important;
    background-color: white;
}
.thirdColumn {
    position:sticky;
    left:127px !important;
    background-color: white;
}
.fourthColumn {
    position:sticky;
    left:127px !important;
    background-color: white;
}
.firstColumnTh {
    position:sticky;
    left:0px !important;
    background-color: white;
}
.secondColumnTh {
    position:sticky;
    left:90px !important;
    background-color: white;
}
.thirdColumnTh {
    position:sticky;
    left:150px !important;
    background-color: white;
}
.fourthColumnTh {
    position:sticky;
    left:150px !important;
    background-color: white;
}
</style>
<script>
  import { core } from '@/config/pluginInit'
  import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
  import { farmMachineryReport } from '../../api/routes'
  import flatpickr from 'flatpickr'
  import ListReportHead from '@/components/custom/ListReportHeadFarm.vue'
  import excel from 'vue-excel-export'
  import ExportPdf from './export_pdf_details'
  import { numberFormatBn, mobileNumber, dateFormat } from '@/Utils/fliter'
  import Vue from 'vue'
  Vue.use(excel)

  export default {
      props: ['id'],
      components: {
          ListReportHead
      },
      created () {},
      data () {
          return {
            language: '',
            orgId: 2,
            stickyHeader: true,
            searchPanel: true,
            search: {
              division_id: 0,
              district_id: 0,
              circular_id: 0,
              upazilla_id: 0,
              season_id: 0,
              machine_type_id: 0,
              engine_no: '',
              mobile_no: '',
              chasis_no: '',
              instrument_name_id: 0,
              fiscal_year_id: 0
            },
            searchResult: {
              engine_no: '',
              mobile_no: '',
              chasis_no: '',
              division_name: '',
              division_name_bn: '',
              district_name: '',
              district_name_bn: '',
              upazilla_name: '',
              upazilla_name_bn: '',
              circular_name: '',
              circular_name_bn: '',
              season: '',
              season_bn: '',
              machine_type: '',
              machine_type_bn: '',
              instrument_name: '',
              instrument_name_bn: '',
              fiscal_year: '',
              fiscal_year_bn: ''
            },
            incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
            datas: [],
            headerExcelDefault: {
              orgName: null,
              orgNameBn: null,
              address: null,
              address_bn: null
            },
            loading: false,
            showData: false,
            subsidyList: [],
            upazilaList: [],
            districtList: [],
            instrumentList: [],
            unionList: [],
            circularList: []
          }
      },
      mounted () {
        this.headerDataExcel()
        core.index()
        flatpickr('.datepicker', {})
      },
      computed: {
        seasonList: function () {
          return [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
          ]
        },
        machineTypeList: function () {
          return this.$store.state.incentiveGrant.commonObj.macMachineTypeList.filter(item => item.status === 1)
        },
        divisionList: function () {
          return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        headerValue: function () {
          const headerVal = []
          if (this.$i18n.locale === 'en') {
              headerVal[0] = this.headerExcelDefault.orgName
              headerVal[1] = this.headerExcelDefault.address
              headerVal[2] = 'Farm Machinery Report'
              headerVal[3] = this.$t('org_pro_division.division') + ':  ' + (this.search.division_id ? this.searchResult.division_name : '') + '; ' + ' ' + this.$t('org_pro_district.district') + ': ' + (this.search.district_id ? this.searchResult.district_name : '')
              headerVal[4] = this.$t('org_pro_upazilla.upazilla') + ':  ' + (this.search.upazilla_id ? this.searchResult.upazilla_name : '') + '; ' + ' ' + this.$t('dae_subsidy.fiscal_year') + ': ' + (this.search.fiscal_year_id ? this.searchResult.fiscal_year : '')
              headerVal[5] = this.$t('dae_config.season_name') + ':  ' + (this.search.season_id ? this.searchResult.season : '') + '; ' + ' ' + this.$t('farm_config.circular') + ': ' + (this.search.circular_id ? this.searchResult.circular_name : '')
              headerVal[6] = this.$t('farm_config.machine_type') + ':  ' + (this.search.machine_type_id ? this.searchResult.machine_type : '') + '; ' + ' ' + this.$t('farm_config.instrument_name') + ': ' + (this.search.instrument_name_id ? this.searchResult.instrument_name : '')
            } else {
              headerVal[0] = this.headerExcelDefault.orgNameBn
              headerVal[1] = this.headerExcelDefault.address_bn
              headerVal[2] = 'ফার্ম মেশিনারী রিপোর্ট'
              headerVal[3] = this.$t('org_pro_division.division') + ':  ' + (this.search.division_id ? this.searchResult.division_name_bn : '') + '; ' + ' ' + this.$t('org_pro_district.district') + ': ' + (this.search.district_id ? this.searchResult.district_name_bn : '')
              headerVal[4] = this.$t('org_pro_upazilla.upazilla') + ':  ' + (this.search.upazilla_id ? this.searchResult.upazilla_name_bn : '') + '; ' + ' ' + this.$t('dae_subsidy.fiscal_year') + ': ' + (this.search.fiscal_year_id ? this.searchResult.fiscal_year_bn : '')
              headerVal[5] = this.$t('dae_config.season_name') + ':  ' + (this.search.season_id ? this.searchResult.season_bn : '') + '; ' + ' ' + this.$t('farm_config.circular') + ': ' + (this.search.circular_id ? this.searchResult.circular_name_bn : '')
              headerVal[6] = this.$t('farm_config.machine_type') + ':  ' + (this.search.machine_type_id ? this.searchResult.machine_type_bn : '') + '; ' + ' ' + this.$t('farm_config.instrument_name') + ': ' + (this.search.instrument_name_id ? this.searchResult.instrument_name_bn : '')
            }
            headerVal[7] = this.$t('farm_report.engine_no') + ':  ' + (this.search.engine_no ? this.search.engine_no : '') + '; ' + ' ' + this.$t('farm_report.chassis_no') + ': ' + (this.search.chasis_no ? this.search.chasis_no : '') + '; ' + ' ' + this.$t('common_config.farmer_mobile_name') + ': ' + (this.search.mobile_no ? this.search.mobile_no : '')
            return headerVal
        },
        excelDatas: function () {
            const excelData = []
            // var serial = 0
            this.datas.map((keyItem2, index2) => {
                keyItem2.upazilla.map((keyItem3, index3) => {
                    keyItem3.list.map((keyItem, index) => {
                        // serial++
                        let farmer = {}
                        if (this.$i18n.locale === 'en') {
                            farmer = {
                            // 'SL No ': keyItem.farmer_serial,
                            'District ': index === 0 ? this.getColumnName(this.$store.state.commonObj.districtList, keyItem.district_id) : '',
                            'Upazila ': index === 0 ? this.getColumnName(this.$store.state.commonObj.upazilaList, keyItem.upazilla_id) : '',
                            'Union ': this.getColumnName(this.$store.state.commonObj.unionList, keyItem.union_id),
                            'Farmers Name': keyItem.name,
                            'Farmers Mobile Number ': keyItem.mobile_no,
                            'Name of Father / Husband ': keyItem.father_name,
                            'Address ': keyItem.address,
                            'NID No ': keyItem.nid_no,
                            'Bill No ': keyItem.bill_no,
                            'Supplier Company Name ': keyItem.supplier_name,
                            'Model ': keyItem.spp_model,
                            'Brand Name ': this.getColumnName(this.$store.state.incentiveGrant.commonObj.macBrandList, keyItem.spp_brand_id),
                            'Agriculture Instrument Name ': this.getInstrumentName(keyItem.instrument_id),
                            'Engine No ': keyItem.engine_no,
                            'Chassis No ': keyItem.chasis_no,
                            '50% ': !keyItem.haor_costal_area ? keyItem.farmer_value : '',
                            '70% ': keyItem.haor_costal_area ? keyItem.farmer_value : '',
                            'Total ': keyItem.farmer_value,
                            'Date of supply of equipment to the farmer ': keyItem.distributed_date ? keyItem.distributed_date : keyItem.excel_date
                            }
                        } else {
                            farmer = {
                            // 'ক্রমিক নং': numberFormatBn(keyItem.farmer_serial, this, { useGrouping: false }),
                            'জেলা ': index === 0 ? this.getColumnName(this.$store.state.commonObj.districtList, keyItem.district_id) : '',
                            'উপজেলা ': index === 0 ? this.getColumnName(this.$store.state.commonObj.upazilaList, keyItem.upazilla_id) : '',
                            'ইউনিয়ন ': this.getColumnName(this.$store.state.commonObj.unionList, keyItem.union_id),
                            'কৃষকের নাম ': keyItem.name_bn,
                            'মোবাইল নং ': this.checkLanguage(keyItem.mobile_no) === 'english' ? mobileNumber(keyItem.mobile_no) : keyItem.mobile_no,
                            'পিতা /স্বামীর নাম': keyItem.father_name_bn,
                            'ঠিকানা ': keyItem.address_bn,
                            'এনআইডি নং ': numberFormatBn(keyItem.nid_no, this, { useGrouping: false }),
                            'বিল নং ': keyItem.bill_no,
                            'সরবরাহকারী প্রতিষ্ঠানের নাম ': keyItem.supplier_name_bn,
                            'মডেল ': keyItem.spp_model,
                            'ব্র্যান্ডের নাম ': this.getColumnName(this.$store.state.incentiveGrant.commonObj.macBrandList, keyItem.spp_brand_id),
                            'কৃষি যন্ত্রের নাম ': this.getInstrumentName(keyItem.instrument_id),
                            'ইঞ্জিন নং ': keyItem.engine_no,
                            'চেসিস নং ': keyItem.chasis_no,
                            '৫০% ': !keyItem.haor_costal_area ? this.$n(keyItem.farmer_value) : '',
                            '৭০% ': keyItem.haor_costal_area ? this.$n(keyItem.farmer_value) : '',
                            'সর্বমোট ': this.$n(keyItem.farmer_value),
                            'কৃষকের নিকট যন্ত্র সরবরাহের তারিখ ': keyItem.distributed_date ? dateFormat(keyItem.distributed_date) : keyItem.excel_date
                            }
                        }
                        excelData.push(farmer)
                    })
                    let upazilaTotal = {}
                    if (this.$i18n.locale === 'en') {
                        upazilaTotal = {
                        // 'SL No ': serial,
                        'District ': '',
                        'Upazila ': 'Upazila Total',
                        'Union ': '',
                        'Farmers Name': '',
                        'Farmers Mobile Number ': '',
                        'Name of Father / Husband ': '',
                        'Address ': '',
                        'NID No ': '',
                        'Bill No ': '',
                        'Supplier Company Name ': '',
                        'Number of instruments ': '',
                        'Model ': '',
                        'Brand Name ': '',
                        'Engine No ': '',
                        'Chassis No ': '',
                        '50% ': '123456',
                        '70% ': '',
                        'Total ': '32423',
                        'Date of supply of equipment to the farmer ': ''
                        }
                    } else {
                        upazilaTotal = {
                        // 'ক্রমিক নং': this.$n(serial),
                        'জেলা ': '',
                        'উপজেলা ': 'উপজেলার সর্বমোট',
                        'ইউনিয়ন ': '',
                        'কৃষকের নাম ': '',
                        'মোবাইল নং ': '',
                        'পিতা /স্বামীর নাম': '',
                        'ঠিকানা ': '',
                        'এনআইডি নং ': '',
                        'বিল নং ': '',
                        'সরবরাহকারী প্রতিষ্ঠানের নাম ': '',
                        'যন্ত্রের সংখ্যা ': '',
                        'মডেল ': '',
                        'ব্র্যান্ডের নাম ': '',
                        'ইঞ্জিন নং ': '',
                        'চেসিস নং ': '',
                        '৫০% ': this.$n(keyItem3.total),
                        '৭০% ': this.$n(keyItem3.total_hawor),
                        'সর্বমোট ': this.$n(keyItem3.total_shomotol),
                        'কৃষকের নিকট যন্ত্র সরবরাহের তারিখ ': ''
                        }
                    }
                    excelData.push(upazilaTotal)
                })
                let districtTotal = {}
                    if (this.$i18n.locale === 'en') {
                        districtTotal = {
                        // 'SL No ': serial,
                        'District ': 'District Total',
                        'Upazila ': '',
                        'Union ': '',
                        'Farmers Name': '',
                        'Farmers Mobile Number ': '',
                        'Name of Father / Husband ': '',
                        'Address ': '',
                        'NID No ': '',
                        'Bill No ': '',
                        'Supplier Company Name ': '',
                        'Number of instruments ': '',
                        'Model ': '',
                        'Brand Name ': '',
                        'Engine No ': '',
                        'Chassis No ': '',
                        '50% ': '123456',
                        '70% ': '',
                        'Total ': '32423',
                        'Date of supply of equipment to the farmer ': ''
                        }
                    } else {
                        districtTotal = {
                        // 'ক্রমিক নং': this.$n(serial),
                        'জেলা ': 'জেলার সর্বমোট',
                        'উপজেলা ': '',
                        'ইউনিয়ন ': '',
                        'কৃষকের নাম ': '',
                        'মোবাইল নং ': '',
                        'পিতা /স্বামীর নাম': '',
                        'ঠিকানা ': '',
                        'এনআইডি নং ': '',
                        'বিল নং ': '',
                        'সরবরাহকারী প্রতিষ্ঠানের নাম ': '',
                        'যন্ত্রের সংখ্যা ': '',
                        'মডেল ': '',
                        'ব্র্যান্ডের নাম ': '',
                        'ইঞ্জিন নং ': '',
                        'চেসিস নং ': '',
                        '৫০% ': this.$n(keyItem2.total),
                        '৭০% ': this.$n(keyItem2.total_hawor),
                        'সর্বমোট ': this.$n(keyItem2.total_shomotol),
                        'কৃষকের নিকট যন্ত্র সরবরাহের তারিখ ': ''
                        }
                    }
                    excelData.push(districtTotal)
            })
            return excelData
        },
        fiscalYearList () {
          return this.$store.state.commonObj.fiscalYearList
        }
      },
      watch: {
        'search.division_id': function (newVal, oldVal) {
          this.districtList = this.getDistrictList(newVal)
        },
        'search.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
        },
        'search.upazilla_id': function (newVal, oldVal) {
          this.unionList = this.getUnionList(newVal)
        },
        'search.fiscal_year_id': function (newValue, oldVal) {
          this.circularList = this.getCircularList()
        },
        'search.season_id': function (newValue, oldVal) {
          this.circularList = this.getSeasonWiseCircularList()
        },
        'search.machine_type_id': function (newVal, oldVal) {
          this.instrumentList = this.getInstrumentList(newVal)
        }
      },
      methods: {
        sidebarMini () {
            core.triggerSet()
            this.$store.dispatch('Setting/miniSidebarAction')
        },
        headerDataExcel () {
            RestApi.getData(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail/2').then(response => {
                if (response.success) {
                    const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 2)
                    const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
                    const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
                    this.headerExcelDefault.orgName = orgName
                    this.headerExcelDefault.orgNameBn = orgNameBn
                    this.headerExcelDefault.address = response.data.address
                    this.headerExcelDefault.address_bn = response.data.address_bn
                }
            })
        },
        searchPanelShow () {
            this.searchPanel = true
        },
        pdfExport () {
          const reportTitle = this.$t('farm_report.farm_report_title')
          ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 2, reportTitle, this.datas, this, this.search, this.searchResult, this.getColumnName)
        },
        getInstrumentList (machineTypeId = null) {
          const machineTypeList = this.$store.state.incentiveGrant.commonObj.instrumentList.filter(item => item.status === 1)
          if (machineTypeId) {
              return machineTypeList.filter(item => item.machine_type_id === machineTypeId)
          }
          return machineTypeList
        },
        getColumnName (list, groupId) {
            const obj = list.find(item => item.value === groupId)
            if (typeof obj !== 'undefined') {
                if (this.$i18n.locale === 'bn') {
                    return obj.text_bn
                } else {
                    return obj.text_en
                }
            } else {
                return ''
            }
        },
        getDistrictList (divisionId = null) {
          const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

          if (divisionId) {
              return districtList.filter(district => district.division_id === divisionId)
          }

          return districtList
        },
        getUpazilaList (districtId = null) {
          const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
              return upazilaList.filter(upazila => upazila.district_id === districtId)
            }

          return upazilaList
        },
        getUnionList (upazillaId = null) {
          const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
            if (upazillaId) {
              return unionList.filter(union => union.upazilla_id === upazillaId)
            }
            return unionList
        },
        async searchData () {
          this.loading = true
          this.showData = true
          if (this.search.division_id) {
            const divisionObj = this.divisionList.find(divisionItem => divisionItem.value === this.search.division_id)
            this.searchResult.division_name = divisionObj !== undefined ? divisionObj.text_en : ''
            this.searchResult.division_name_bn = divisionObj !== undefined ? divisionObj.text_bn : ''
          }
          if (this.search.district_id) {
            const districtObj = this.$store.state.commonObj.districtList.find(districtItem => districtItem.value === this.search.district_id)
            this.searchResult.district_name = districtObj !== undefined ? districtObj.text_en : ''
            this.searchResult.district_name_bn = districtObj !== undefined ? districtObj.text_bn : ''
          }
          if (this.search.upazilla_id) {
            const upazillaObj = this.$store.state.commonObj.upazilaList.find(disItem => disItem.value === this.search.upazilla_id)
            this.searchResult.upazilla_name = upazillaObj !== undefined ? upazillaObj.text_en : ''
            this.searchResult.upazilla_name_bn = upazillaObj !== undefined ? upazillaObj.text_bn : ''
          }
          if (this.search.fiscal_year_id) {
            const fiscalYearListObj = this.$store.state.commonObj.fiscalYearList.find(disItem => disItem.value === this.search.fiscal_year_id)
            this.searchResult.fiscal_year = fiscalYearListObj !== undefined ? fiscalYearListObj.text_en : ''
            this.searchResult.fiscal_year_bn = fiscalYearListObj !== undefined ? fiscalYearListObj.text_bn : ''
          }
          if (this.search.season_id) {
            const seasonObj = this.seasonList.find(disItem => disItem.value === this.search.season_id)
            this.searchResult.season = seasonObj !== undefined ? seasonObj.text_en : ''
            this.searchResult.season_bn = seasonObj !== undefined ? seasonObj.text_bn : ''
          }
          if (this.search.machine_type_id) {
            const mashObj = this.$store.state.incentiveGrant.commonObj.macMachineTypeList.find(macMachineTypeList => macMachineTypeList.value === this.search.machine_type_id)
            this.searchResult.machine_type = mashObj !== undefined ? mashObj.text_en : ''
            this.searchResult.machine_type_bn = mashObj !== undefined ? mashObj.text_bn : ''
          }
          if (this.search.instrument_name_id) {
            const instrumentListObj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(macMachineTypeList => macMachineTypeList.value === this.search.instrument_name_id)
            this.searchResult.instrument_name = instrumentListObj !== undefined ? instrumentListObj.text_en : ''
            this.searchResult.instrument_name_bn = instrumentListObj !== undefined ? instrumentListObj.text_bn : ''
          }
          if (this.search.circular_id) {
            const circularObj = this.$store.state.incentiveGrant.commonObj.circularList.find(circularList => circularList.value === this.search.circular_id)
            this.searchResult.circular_name = circularObj !== undefined ? circularObj.text_en : ''
            this.searchResult.circular_name_bn = circularObj !== undefined ? circularObj.text_bn : ''
          }
          const params = Object.assign({}, this.search)
          await RestApi.getData(incentiveGrantServiceBaseUrl, farmMachineryReport, params).then(response => {
            if (response.success) {
                this.searchPanel = false
              this.datas = response.data
              this.sidebarMini()
            } else {
              this.datas = []
            }
          })
          this.loading = false
        },
        getStatus (status) {
          if (status === 2) {
              return this.$i18n.locale === 'bn' ? 'অনুমোদিত' : 'Approved'
          } else {
            return this.$i18n.locale === 'bn' ? 'প্রত্যাখ্যান' : 'Reject'
          }
        },
        getSeasonWiseCircularList () {
          const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.season_id === this.search.season_id && item.circular_type === 1)
          if (this.search.fiscal_year_id) {
            return circularList.filter(item => item.fiscal_year_id === this.search.fiscal_year_id)
          }
          return circularList
        },
        getCircularList () {
          const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.fiscal_year_id === this.search.fiscal_year_id && item.circular_type === 1)
          if (this.search.season_id) {
            return circularList.filter(item => item.season_id === this.search.season_id)
          }
          return circularList
        },
        checkLanguage (inputNumber) {
            // Define patterns for English and Bangla numbers
            const englishPattern = /^[0-9]+$/
            const banglaPattern = /^[০-৯]+$/

            if (englishPattern.test(inputNumber)) {
                return 'english'
            } else if (banglaPattern.test(inputNumber)) {
                return 'bangla'
            } else {
                return ''
            }
        },
        getInstrumentName (id) {
            const obj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(item => item.value === id)
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        }
      }
  }
  </script>
